import { Keyboard } from './components/Keyboard';
import styled from 'styled-components';
import { GrowingTextInput } from './components/TextInput';
import { Header } from './components/Header';
import { HelpModal } from './components/HelpModal';
import { CompletedModal } from './components/CompletedModal';
import { atom, useRecoilState } from 'recoil';
import { HomePage } from './HomePage';

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: #292E43;
  height: 100%;
  max-width: 900px;
  font-size: calc(10px + 2vmin);
  color: white;
`;

const Divider = styled.hr`
  border: 1px solid #99D2FC;
  width: 65%;
  margin-right: 10px;
  margin-left: 10px;
`

const ActionsContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 20px;
`;

const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: #292E43;
`;

export const homePageState = atom({
  key: 'homePageState',
  default: true,
})

function App() {
  const [homeOpen, setHomeOpen] = useRecoilState(homePageState);
  return (
    <Wrapper>
      {homeOpen ? <HomePage /> : (
        <>
          <Layout>
            <Header />
            <GrowingTextInput />
            <Divider />
            <ActionsContainer>
              <Keyboard />
            </ActionsContainer>
          </Layout>
          <HelpModal />
          <CompletedModal />
        </>
      )}
    </Wrapper>
  );
}

export default App;
