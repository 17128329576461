import React, { useEffect } from "react";
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components"
import { helperModalState } from "./HelpModal";
import { IoMdInformationCircle } from "react-icons/io";
import { completedModalState } from "./CompletedModal";
import { useTextInput } from "./TextInput";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const Text = styled.div`
  font-weight: 600;
`;

export const timerState = atom({
  key: 'timerState',
  default: 60 * 3,
})

export const scoreState = atom({
  key: 'scoreState',
  default: 0,
})

export const formattedTime = (time: number) => {
  if (time < 0) {
    return "0:00"
  }
  const s = time % 60;
  const m = Math.floor(time / 60);
  return `${m}:${s > 9 ? s : '0' + s}`
}

export const Header = () => {
  const [timer, setTimer] = useRecoilState(timerState);
  const [score, setScore] = useRecoilState(scoreState);
  const { words } = useTextInput();

  useEffect(() => {
    let s = 0;
    words.forEach((w: any) => {
      s += 10 + 5 * (w.text.length - 4)
    })
    setScore(s);
  }, [words]);
  
  const setCompletedModal = useSetRecoilState(completedModalState);

  const setIsOpen = useSetRecoilState(helperModalState);
  const openModal = () => setIsOpen(true);

  useEffect(() => {
    const t = setInterval(() => {
      if (timer < 1) {
        setCompletedModal(true);
      }
      setTimer(timer - 1);
    }, 1000);
    return () => clearInterval(t);
  }, [timer]);

  return (
    <Container>
      <Text style={{ marginTop: 20 }}>{score || '0'}</Text>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, gap: 20 }}>
        <IoMdInformationCircle onClick={openModal}/>
        <Text style={{ fontSize: 32}}>CAT</Text>
      </div>
      <Text style={{ textAlign: 'right', marginTop: 20 }}>{formattedTime(timer)}</Text>
    </Container>
  )
}