import Modal from 'react-modal';
import { atom, useRecoilState } from 'recoil';
const customStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column' as any,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#292E43',
    top: '50%',
    left: '50%',
    right: 'auto',
    borderRadius: 15,
    bottom: 'auto',
    padding: 30,
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
  },
};

export const helperModalState = atom({
  key: 'HelperModalState',
  default: false,
})

export const HelpModal = () => {
  const [isOpen, setIsOpen] = useRecoilState(helperModalState);
  const onClose = () => setIsOpen(false);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <h2 style={{ color: 'white', textAlign: 'center' }}>Welcome to Trigram Towers</h2>
      <div>
        <p>Your objective is to build the tallest and fastest word tower using a specific trigram (e.g., LOG) before time runs out.</p>
        <p>Example: LOGS, LOGIC, LOGICS, CATALOG, LOGISTIC, PATHOLOGY, PSYCHOLOGY etc.</p>
        <p><strong>Rules:</strong></p>
        <ul>
          <li>The word must contain the trigram with no letters in between.</li>
          <li>Minimum word length is 4 letters.</li>
          <li>Plurals, prefixes, and suffixes (like pre-, -ness, and -ing) are allowed.</li>
          <li>No special characters or hyphens.</li>
        </ul>
        <p><strong>Scoring:</strong></p>
        <ul>
          <li>4-letter word: 10 points.</li>
          <li>+5 points for each extra letter. (e.g., 6-letter word = 20 points)</li>
        </ul>
      </div>
      <div style={{ backgroundColor: "#3C425C", color: 'white', padding: 15, borderRadius: 6, cursor: 'pointer' }} onClick={onClose}>Close</div>
    </Modal>
  );
}