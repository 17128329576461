import React from "react"
import styled from "styled-components";
import { useTextInput } from "./TextInput";

const letterRows = [
  ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
  ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', '←'],
  ['Z', 'X', 'C', 'V', 'B', 'N', 'M', 'ENTER']
]

const KeyboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;

const Letter = styled.div`
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  height: 52px;
  font-size: 22px;
  background-color: #3C425C;
  border-radius: 5px;
`;

export const Keyboard = () => {
  const { handleWordChange, onSubmit, inputValue } = useTextInput();

  const onPress = (k: string) => {
    if (k === '←' && inputValue.length > 0) {
      handleWordChange(inputValue.slice(0, inputValue.length - 1))
    } else if (k === 'ENTER') {
      onSubmit();
    } else {
      handleWordChange(inputValue + k)
    }
  }
  
  return (
    <KeyboardContainer>
      {letterRows.map(row => (
        <RowContainer>
          {row.map(letter => <Letter onClick={() => onPress(letter)} style={{ paddingLeft: letter === 'ENTER' ? 10 : 0, paddingRight: letter === 'ENTER' ? 10 : 0, fontSize: letter === 'ENTER' ? 16 : undefined,  }}>{letter}</Letter>)}
        </RowContainer>
      ))}
    </KeyboardContainer>    
  )
}