import styled from 'styled-components';
import { helperModalState, HelpModal } from './components/HelpModal';
import { CompletedModal } from './components/CompletedModal';
import { useSetRecoilState } from 'recoil';
import { homePageState } from './App';

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #292E43;
  max-width: 900px;
  font-size: calc(10px + 2vmin);
  color: white;
  gap: 15px;
`;

const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: #292E43;
`;

const Button = styled.div`
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  height: 52px;
  font-size: 22px;
  background-color: #3C425C;
  border-radius: 5px;
  padding-right: 20px;
  padding-left: 20px;
  cursor: pointer;
`;

export const HomePage = () => {
  const openHelpModal = useSetRecoilState(helperModalState);
  const setHomeOpen = useSetRecoilState(homePageState);

  return (
    <Wrapper>
      <Layout>
        <h2 style={{ color: 'white', textAlign: 'center' }}>Welcome to Trigram Towers</h2>
        <Button onClick={() => setHomeOpen(false)}>Play Now</Button>
        <Button onClick={() => openHelpModal(true)}>How to Play?</Button>
      </Layout>
      <HelpModal />
      <CompletedModal />
    </Wrapper>
  );
}
