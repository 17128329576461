import Modal from 'react-modal';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTextInput } from './TextInput';
import { scoreState, timerState } from './Header';
const customStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column' as any,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#292E43',
    top: '50%',
    left: '50%',
    right: 'auto',
    borderRadius: 15,
    bottom: 'auto',
    padding: 30,
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
  },
};

export const completedModalState = atom({
  key: 'CompletedModalState',
  default: false,
});

const countWordLengths = (words: any[]) => {
  const lengthCounts = {} as any;
  words.forEach(word => {
      const length = word.text.length;
      lengthCounts[length] = (lengthCounts[length] || 0) + 1;
  });
  return lengthCounts;
}

export const CompletedModal = () => {
  const [isOpen, setIsOpen] = useRecoilState(completedModalState);
  const score = useRecoilValue(scoreState);
  const { words, setWords, handleWordChange } = useTextInput();
  const setTimer = useSetRecoilState(timerState);
  const onClose = () => {
    setIsOpen(false);
    setWords([]);
    handleWordChange('');
    setTimer(60 * 3);
  }
  const lengthCounts = countWordLengths(words);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <h2 style={{ color: 'white', textAlign: 'center' }}>Game Completed</h2>
      <div>
        <p>Your final score is {score}.</p>
        <p>You entered a total of {words.length} words:</p>
        <ul>
          {Object.keys(lengthCounts)
            .sort((a: any, b: any) => a - b)
            .map(length => <li>{lengthCounts[length]} word(s) entered with length {length}</li>)}
        </ul>
      </div>
      <div style={{ backgroundColor: "#3C425C", color: 'white', padding: 15, borderRadius: 6, cursor: 'pointer' }} onClick={onClose}>Close</div>
    </Modal>
  );
}