import './AnimatedStack.css';
import { allWords } from './data';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

export const inputValueState = atom({
  key: 'InputValueState',
  default: '',
});

export const errorMessageState = atom({
  key: 'ErrorMessage',
  default: ''
});

export const wordsState = atom({
  key: 'words',
  default: [] as any,
});

export const useTextInput = () => {
  const [inputValue, setInputValue] = useRecoilState(inputValueState)
  const [errorMessage, setErrorMessage] = useRecoilState(errorMessageState);
  const [words, setWords] = useRecoilState(wordsState);

  const handleWordChange = (word: string) => {
    setErrorMessage('');
    word = word.toUpperCase().trim();
    if (!word.length) {
      setInputValue('');
      return;
    }
    const lastLetter = word[word.length - 1];
    if ('A' <= lastLetter && lastLetter <= 'Z') {
      setInputValue(word)
    } else {
      setInputValue(word.substring(0, word.length - 1));
    }
  }

  const onSubmit = () => {
    if (inputValue.length <= 4) {
      setErrorMessage('Must be more than 3 characters');
      return;
    }

    if (!!words.find((w: any) => w.text === inputValue)) {
      setErrorMessage('Already entered word');
      return;
    }

    if (!allWords.has(inputValue.toLowerCase())) {
      setErrorMessage('Invalid word');
      return;
    }

    // Add the new word to the top of the stack
    const newWords = [{ text: inputValue, animate: true }, ...words];
    if (newWords.length > 5) {
      newWords.pop();
    }
    setWords(newWords);
    setInputValue('');
  }

  return {
    words,
    inputValue,
    handleWordChange,
    onSubmit,
    setWords,
  }
}

export const GrowingTextInput = () => {
  const [words, setWords] = useRecoilState(wordsState);
  const inputValue = useRecoilValue(inputValueState)
  const errorMessage = useRecoilValue(errorMessageState);

  const { handleWordChange, onSubmit } = useTextInput();

  const handleInputChange = (e: any) => handleWordChange(e.target.value);

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && inputValue) {
      onSubmit();
    }
  };

  return (
    <div className="container">
      <div className='input-container'>
        <input
          type="text"
          value={inputValue}
          inputMode="none"
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          spellCheck={false}
          className={errorMessage ? 'input-box error-shake' : 'input-box'}
        />  
        <p style={{ minHeight: 30, padding: 0, margin: 0, textAlign: 'center', marginTop: 10, fontSize: 12, width: '100%', color: 'red' }}>{errorMessage || ' '}</p>
      </div>
      <div className="stack">
        {words.slice(0, 5).map((word: any, index: any) => (
          <div
            key={index}
            className={`word-box ${word.animate ? 'animate-drop' : ''}`}
            onAnimationEnd={() => {
              console.log(words);
              setWords(words.map((w: any, i: number) => {
                let x = JSON.parse(JSON.stringify(w));
                if (index === i) {
                  x.animate = false;
                }
                return x;
              }));
            }}
          >
            {word.text}
          </div>
        ))}
      </div>
    </div>
  );
}
